@media print {
  .c-header {
    position: relative !important; 
  }

  .c-header-banner,
  .c-header-sub-banner {
    display: none;
  }

  .c-body {
    margin-top: 0 !important;
  }

  .admin .citation-page.details-page {
    .sections .section.violations {
      page-break-before: always;
      page-break-inside: avoid;
      page-break-after: always;
    }
  }
}