@import 'styles/custom/_mixins';
@import 'styles/custom/_variables';

.c-header-top {
  gap: 1rem;
  background-color: $ldwf-primary-green;
  min-height: 136px;
  padding-left: 1rem;
  padding-right: 1rem;

  img {
    padding: 1rem;
  }

  .title,
  .subtitle {
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.25rem;

    @include media-breakpoint-down(md) {
      letter-spacing: 0.1rem;
    }
  }

  .title {
    font-size: xx-large;

    @include media-breakpoint-down(lg) {
      font-size: x-large;
    }

    @include media-breakpoint-down(md) {
      font-size: large;
      font-weight: bold;
      letter-spacing: 0.1rem;
    }
  }
}

