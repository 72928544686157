@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_mixins';
@import '~@coreui/coreui/scss/_variables';

//General styles
$input-font-size: 14px;
$input-font-color: #3c4b64;

//Sidebar
//$sidebar-brand-height pulled from coreui variables -- changing the subtracted value will scale the brand image proportionally
$sidebar-brand-image-height: $sidebar-brand-height - 10px;
$collapsed-sidebar-width: 56px;
$sidebar-list-item-height: 48px;

// LDWF Colors
$ldwf-primary-green: rgb(69, 90, 33);
$ldwf-primary-khaki: rgb(238, 225, 197);
$ldwf-black: rgb(35, 31, 32);
$ldwf-sage-green: rgb(116, 133, 68);
$ldwf-dark-cream: rgb(231, 210, 123);
$ldwf-cream: rgb(241, 234, 223);
$ldwf-brown: rgb(121, 97, 77);
$ldwf-taupe: rgb(168, 161, 140);
$ldwf-dark-brown: rgb(88, 65, 47);
$ldwf-charcoal-gray: rgb(115, 106, 98);
$ldwf-almond: rgb(185, 156, 106);
$ldwf-brick-red: rgb(143, 60, 31);
$ldwf-pumpkin: rgb(218, 118, 39);
$ldwf-mustard: rgb(187, 163, 48);
$ldwf-midnight-blue: rgb(71, 93, 108);
$ldwf-smoky-blue: rgb(130, 146, 159);
$ldwf-eggplant: rgb(110, 82, 97);