@import 'styles/custom/_mixins';
@import 'styles/custom/_variables';

.c-header-navigation {
  align-items: stretch;
  background-color: $ldwf-sage-green;
  color: white;
  padding: 0;

  .navbar-nav {
    flex-direction: row;

    .nav-item {
      display: flex;
    }

    .nav-item > a.btn {
      border-radius: 0;
      display: flex;
      align-items: center;
      color: white;
      font-size: medium;
      padding: 0 2rem;
      letter-spacing: 0.05rem;

      &.active {
        background-color: adjust-color($color: $ldwf-primary-green, $lightness: +5%);
      }

      &:hover {
        text-decoration: none;

        &:not(.active) {
          background-color: adjust-color($color: $ldwf-primary-green, $lightness: +10%);
        }
      }
    }

    a.dropdown-item:active,
    button.dropdown-item:active {
      background-color: $ldwf-midnight-blue;
    }
  }

  .user-dropdown .user-email {
    &,
    > *:hover {
      color: white;
    }
  }
}