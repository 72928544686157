//Create new overrides and import them here as you need them.
@import './_mixins';
@import 'navbar';
@import 'variables';
@import 'print';

.app-header .dropdown-item {
  min-width: unset;
}

.c-header-nav {
  width: 100%;
}

.c-header-nav .dropdown-item {
  min-width: 100px;
}

.c-header-toggler {
  margin-right: 20px;
}

.dropdown-item i {
  margin-right: 10px;
  margin-left: -10px;
}

.form-control,
.form-control:focus {
  font-size: $input-font-size;
  color: $input-font-color;
}

html {
  //removes 300ms touch delay on mobile
  touch-action: manipulation;
}

html,
body {
  min-height: 100vh;
}

.react-select-input {
  font-size: $input-font-size;

  > div:first-of-type {
    border-radius: 0.25rem;
  }
}

.ReactTable {
  .rt-table .rt-thead.-header {
    box-shadow: none;
    .rt-tr .rt-th {
      font-weight: bold;
      text-align: left;
      padding: 10px;
    }
  }

  .rt-table .rt-tbody {
    .rt-tr .rt-td {
      padding: 10px;
    }
  }

  .pagination-bottom .-pagination {
    box-shadow: none;
  }
}

.user-email {
  :hover {
    color: black;
    cursor: pointer;
  }
  color: #536c79;
  cursor: pointer;
}

.Toastify {
  font-size: medium;

  .Toastify__toast-body {
    display: flex;
    justify-content: center;
  }

  .Toastify__toast--success {
    background-color: $ldwf-midnight-blue;
  }
}

.btn.btn-primary {
  background-color: $ldwf-midnight-blue;
  border-color: $ldwf-midnight-blue;
  color: white;
  letter-spacing: 0.025rem;
  gap: 0.5rem;

  &:hover {
    background-color: adjust-color(
      $color: $ldwf-midnight-blue,
      $lightness: -5%
    ) !important;
    border-color: $ldwf-smoky-blue !important;
  }
}

.react-date-picker {
  padding: 0;

  &.is-invalid {
    background-image: none;
    padding-right: 0 !important;
  }
}

.react-date-picker > span {
  z-index: 1060;

  .react-date-picker__calendar {
    z-index: 1060;
  }
}

h5 {
  font-weight: 400;
  font-size: large
}

[class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}