@import 'styles/custom/_variables';

.unauthenticated .sign-in-page {
  display: flex; 
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 600px;
    padding-top: 10rem;

    > h1 {
      font-size: 3.5rem;
      padding-bottom: 0.5rem
    }

    > p {
      font-size: 1.25rem;
      padding-bottom: 1rem;
    }

    .input-group {
      display: flex;
      padding-bottom: 2rem;
      
      .input-group-text {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      input {
        font-size: large;
        padding: 1.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .invalid-feedback {
        font-size: large;
      }
    }

    .btn {
      letter-spacing: 0.025rem;

      &.sign-in {
        border: 1px solid $ldwf-midnight-blue;
        background-color: $ldwf-midnight-blue;
        border-color: $ldwf-midnight-blue;
        color: white;
        margin-right: 0.5rem;

        &:hover {
          background-color: adjust-color($color: $ldwf-midnight-blue, $lightness: -5%) !important;
          border-color: $ldwf-smoky-blue !important;
        }
      }
    }

    .forgot-password {
      margin-top: 1.5rem;
      font-size: x-large;

      a {
        color: $ldwf-smoky-blue;
      }
    }
  }
}