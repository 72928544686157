.c-body {
  transition: margin 0.35s ease;
  margin-top: 193px;

  &.with-banner,
  &.with-sub-banner {
    margin-top: 249px;
  }

  &.with-banner.with-sub-banner {
    margin-top: 305px;
  }
}