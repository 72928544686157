@import 'styles/custom/_variables';

.c-header {
  border-bottom: none;
}

.c-wrapper {
  background-color: white;
}

.c-body {
  background-color: white;
  flex-direction: row;

  .container > .c-main {
    padding-top: 0;

    > .page {
      height: 100%;

      .alert {
        font-size: medium;
      }

      &.unauthorized-page,
      &.not-found-page {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          padding-top: 15rem;
          max-width: 600px;

          h1 {
            font-size: 4rem;
            padding-bottom: 0.5rem;
          }

          h4 {
            font-size: 2rem;
            margin-bottom: 2rem;
          }

          p {
            font-size: 1.5rem;
            margin-bottom: 2rem;
          }

          a {
            color: $ldwf-midnight-blue;
            font-size: x-large;
          }
        }
      }
    }
  }
}

#wildlife-iframe {
  margin-top: 192px;

  #wildlife-iframe-inner {
    border: none;

    &:not(:first-of-type) {
      display: none;
    }
  }
}

.btn.disabled {
  cursor: default;
}